import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/Home'
import Slovenia from './components/Slovenia'
import Abroad from './components/Abroad'
import Warehousing from './components/Warehousing'
import Other from './components/Other'
import Privacy from './components/Privacy'
import Responsibility from './components/Responsibility'

const lngs = {
  en: { nativeName: 'English' },
  slo: { nativeName: 'Slovenian' }
};


const App = () => {

   const { t, i18n } = useTranslation();
   const location = useLocation();
   useEffect(() => {
           window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant'
  });
   }, [location]);

  return(
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/slovenia" element={<Slovenia />} />
          <Route path="/abroad" element={<Abroad />} />
          <Route path="/warehousing" element={<Warehousing />} />
          <Route path="/other" element={<Other />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/responsibility" element={<Responsibility />} />
        </Routes>
  )

}

export default App;

import '../styles/abroad.css';
import '../styles/App.css';
import '../styles/readMore.css';
import AbroadNavbar from '../components/navbars/AbroadNavbar';
import ExpandableText from './ReadMore';
import * as React from 'react';
import emailjs from '@emailjs/browser';
import Pdf1 from '../assets/pdf1.pdf';
import Pdf2 from '../assets/pdf2.pdf';
import Pdf3 from '../assets/pdf3.pdf';
import Pdf4 from '../assets/pdf4.pdf';
import Pdf5 from '../assets/pdf5.pdf';
import Pdf6 from '../assets/pdf6.pdf';
import Pdf7 from '../assets/pdf7.pdf';
import Pdf8 from '../assets/pdf8.pdf';
import { useTranslation} from 'react-i18next';
import Footer from './Footer'
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import Calculator from './Calculator';

function Abroad() {
  
    const { t, i18n } = useTranslation();
    const form3 = React.useRef();

    const nasvetiArray = [
      [i18next.t('abroadAdvicesArr1'), i18next.t('abroadAdvicesArr1-2'), i18next.t('abroadAdvicesArr1-3'), i18next.t('abroadAdvicesArr1-4')],
      [i18next.t('abroadAdvicesArr2'), i18next.t('abroadAdvicesArr2-2'), i18next.t('abroadAdvicesArr2-3'), i18next.t('abroadAdvicesArr2-4'), i18next.t('abroadAdvicesArr2-5'), i18next.t('abroadAdvicesArr2-6'), i18next.t('abroadAdvicesArr2-7'), i18next.t('abroadAdvicesArr2-8'), i18next.t('abroadAdvicesArr2-9'), i18next.t('abroadAdvicesArr2-10'), i18next.t('abroadAdvicesArr2-11')],
      [i18next.t('abroadAdvicesArr3'), i18next.t('abroadAdvicesArr3-2'), i18next.t('abroadAdvicesArr3-3'), i18next.t('abroadAdvicesArr3-4'), i18next.t('abroadAdvicesArr3-5'), i18next.t('abroadAdvicesArr3-6'), i18next.t('abroadAdvicesArr3-7')],
      [i18next.t('abroadAdvicesArr4'), i18next.t('abroadAdvicesArr4-2'), i18next.t('abroadAdvicesArr4-3'), i18next.t('abroadAdvicesArr4-4'), i18next.t('abroadAdvicesArr4-5'), i18next.t('abroadAdvicesArr4-6'), i18next.t('abroadAdvicesArr4-7'), i18next.t('abroadAdvicesArr4-8'), i18next.t('abroadAdvicesArr4-9')],
      [i18next.t('abroadAdvicesArr5'), i18next.t('abroadAdvicesArr5-2'), i18next.t('abroadAdvicesArr5-3'), i18next.t('abroadAdvicesArr5-4'), i18next.t('abroadAdvicesArr5-5'),i18next.t('abroadAdvicesArr5-6')],
      [i18next.t('abroadAdvicesArr6')],
      [i18next.t('abroadAdvicesArr7'),i18next.t('abroadAdvicesArr7-2'),i18next.t('abroadAdvicesArr7-3'),i18next.t('abroadAdvicesArr7-4'),i18next.t('abroadAdvicesArr7-5')],
      [i18next.t('abroadAdvicesArr8')],
    ];
    const linkiArray = [
      {"Personal effects accounting document": Pdf1
      },
      {"Supplemental Declaration with instruction": Pdf2,
      "Customs power of attorney": Pdf3,
      "United States Environmental Protection Agency": Pdf4,
      "Declaration for free entry of unaccompanied articles": Pdf5,
      },
      {"Unaccompanied personal baggage declaration": Pdf6,
      "New Zealand sea container quarantine declaration": Pdf7,
      },
      {"Unaccompanied personal effects statement": Pdf8,
      },
      {"":"#"}
    ]
    const [selectedItems, setSelectedItems] = useState([]);
    const handleSelectedItemsChange = (items) => {
      setSelectedItems(items);
    };
    const sendEmail = (e) => {
      e.preventDefault();

      console.log("form current", form3.current)
      //form.current.furnishingItems = livingRoomItems;
  
      
      const serializedItems = selectedItems
      .map(item => `${item.name}: ${item.quantity}`)
      .join(', ');
  
    // Create a hidden input to store the serialized selected items
    const selectedItemsInput = document.createElement("input");
    selectedItemsInput.setAttribute("type", "hidden");
    selectedItemsInput.setAttribute("name", "selected_items");
    selectedItemsInput.setAttribute("value", serializedItems);
  
    // Append the hidden input to the form
    form3.current.appendChild(selectedItemsInput);
    
    emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form3.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            alert('message sent successfully...');
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
        form3.current.removeChild(selectedItemsInput);
      };
  return (
    <div className="AbroadContainer">
    <AbroadNavbar />
    <div className='heroPage'>
        <div className="underHeaderHeroTextAbroad"><h3>{t('abroadHeaderSubheader1')}</h3>
        </div>
        <div className="abroadContentContainer">
          <div className="abroadInnerContainer">
        <div className="pageText">
        <h2>{t('abroadHeaderSubheader2')}</h2>
        <p>
        {t('abroadHeaderSubheader3')}
        <br/>
        {t('abroadHeaderSubheader4')}
        <br/>
        {t('abroadHeaderSubheader5')}
        </p>
        </div>
        <div className='container'>
        <div className='card'>
            {/* Only show 120 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle1')} htmlType="p">
            {t('abroadText1')}
            </ExpandableText>
        </div><br />

        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle2')} htmlType="p">
            {t('abroadText2')}
            <br /><br />
            {t('abroadText2-2')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle3')} htmlType="p">
            {t('abroadText3')}
            <br /><br />
            {t('abroadText3-2')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle4')} htmlType="p">
            {t('abroadText4')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle5')} htmlType="p">
            {t('abroadText5')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle6')} htmlType="p">
            {t('abroadText6')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle7')} htmlType="p">
            {t('abroadText7')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle8')} htmlType="p">
            {t('abroadText8')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle9')} htmlType="p">
            {t('abroadText9')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle10')} htmlType="p">
            {t('abroadText10')}
            <br /><br />
            {t('abroadText10-2')}
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle11')} htmlType="p">
            {t('abroadText11')}
            </ExpandableText>

        </div><br />
        <span className="pageText">
        <h2>{t('abroadSpecializedTitle')}</h2>
        </span>
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle12')} htmlType="a" html = {null} htmlText={linkiArray[0]} arrIndex = {0}>
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle13')} htmlType="a"  html = {null} htmlText={linkiArray[1]} arrIndex = {1}>
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle14')} htmlType="a" html = {null} htmlText={linkiArray[2]} arrIndex = {2} >
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle15')} htmlType="a"  html = {null} htmlText={linkiArray[3]} arrIndex = {3}>
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle16')} htmlType="a" html = {null} htmlText={null} arrIndex = {4}>
            </ExpandableText>

        </div><br />
        <span className="pageText">
        <h2>{t('abroadAdvice1')}</h2>
        <p>{t('abroadAdvice2')}
          <br/>
          {t('abroadAdvice3')}
        </p>
        </span>
        <div className='card'>
            {/* fix params here */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle17')} htmlType="li" html = {null} htmlText={nasvetiArray} arrIndex = {0}>
            
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle18')} htmlType="li" html = {null} htmlText={nasvetiArray} arrIndex = {1}>
            
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle19')} htmlType="li" html = {null} htmlText={nasvetiArray} arrIndex = {2}>
            
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle20')} htmlType="li" html = {null} htmlText={nasvetiArray} arrIndex = {3}> 
            
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle21')} htmlType="li" html = {null} htmlText={nasvetiArray} arrIndex = {4}>
            
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle22')} htmlType="li" html = {null} htmlText={nasvetiArray} arrIndex = {5}>
            
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle23')} htmlType="li" html = {null} htmlText={nasvetiArray} arrIndex = {6}>
            
            </ExpandableText>

        </div><br />
        <div className='card'>
            {/* Show 200 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('abroadTitle24')} htmlType="li" html = {null} htmlText={nasvetiArray} arrIndex = {7}>
            
            </ExpandableText>

        </div><br />
      </div>
        <div className="inquiryPage">
        <div className="inquiryFormContainer">
        <div className="inquiryText blueTitle">
              <h2>{t('homePovpraševanje1')}</h2>
            </div>
        <div className="inquiryTitle">
          <h3>{t('homePovpraševanje3')}</h3>
        </div>
          <div className="inquiryFormTitle">
            <h2>{t('homeServicesMainTitle2')}</h2>
          </div>
          <div className="inquiryFormInnerContainer">

            <form onSubmit={sendEmail} ref={form3}>
            <span className="radioButtons">
              <span className="radioButtonsLeft">
                <span>
              <input type="radio" id="selitvenaEmbalaza" name="radio" defaultValue={t('inquiryGlobal2')} />
              <label htmlFor="selitvenaEmbalaza">{t('inquiryGlobal2')}</label>
                </span>
              </span>
              <span className="radioButtonsRight">
                <span>
              <input type="radio" id="skladisce" name="radio" defaultValue={t('inquiryGlobal4')} />
              <label htmlFor="skladisce">{t('inquiryGlobal4')}</label> 
              </span>
              </span>
            </span>
            <span className="lowerHalfOfForm">
              <span className="leftForm">
              <input type="text" id="imePriimek" name="imePriimek" placeholder={t('inquiryGlobal7')} /><br />
              <input type="text" id="email" name="email" placeholder={t('inquiryGlobal8')}/><br />
              <span className="inLineInput">
              <input type="text" id="selimIz" name="selimIz" placeholder={t('inquiryGlobal9')}/>
              <input type="text" id="selimV" name="selimV" placeholder={t('inquiryGlobal10')}/><br />
              </span>
              <span className="selectContainer">
              <span className="selectContainer" id="quickfixCalc">
              <Calculator onSelectedItemsChange={handleSelectedItemsChange}/>
              </span>
              </span>
              <input type="text" id="telefon" name="telefon" placeholder={t('inquiryGlobal11')}/><br />
            </span>
            <span className="rightForm">
              <textarea placeholder={t('inquiryGlobal12')} maxLength='1000' minLength='100' rows="16" cols="89">
                
              </textarea>
            </span>
              
            </span>
            <span className="submits">
            <input type="submit" value={t('inquiryGlobal13')} id="firstSubmit" />
            </span>

            </form>

          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Abroad;
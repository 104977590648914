import '../styles/privacy-responsibility.css';
import WarehousingNavbar from '../components/navbars/WarehousingNavbar';
import * as React from 'react';
import { useTranslation} from 'react-i18next';
import Footer from './Footer'

function Privacy() {

    const { t, i18n } = useTranslation();
    //i18n.changeLanguage("en");

  return (
    <div className="SloveniaContainer">
    <WarehousingNavbar />
    <div className='heroPage'>
        <div className="underHeaderHeroTextWarehousing"><h3>{t('WarehouseHeroText1')}</h3>
        </div>
        <div className="contentContainer" id="warehousingContentBg">
          <div className="SloContentInnerContainer">
        <div className="privacyFirstTextContainer">
        <h1>{t('privacy-mainTitle')}</h1>
        <div className="privacyTextContainer">
        <h2>{t('privacy-subTitle1')}</h2>
        <p>{t('privacy-subText1-1')}</p>
        <p>{t('privacy-subText1-2')}</p>
        </div>

        <div className="privacyTextContainer">
        <h2>{t('privacy-subTitle2')}</h2>
        <p>{t('privacy-subText2-1')}</p>
        </div>

        <div className="privacyTextContainer">
        <h2>{t('privacy-subTitle3')}</h2>
        <p>{t('privacy-subText3-1')}</p>
        </div>

        <div className="privacyTextContainer">
        <h2>{t('privacy-subTitle4')}</h2>
        <p>{t('privacy-subText4-1')}</p>
        </div>

        <div className="privacyTextContainer">
        <h2>{t('privacy-subTitle5')}</h2>
        <p>{t('privacy-subText5-1')}</p>
        </div>

        <div className="privacyTextContainer">
        <h2>{t('privacy-subTitle6')}</h2>
        <p>{t('privacy-subText6-1')}</p><br/>
        <p>{t('privacy-subText6-2')}</p>
        </div>

        <div className="privacyTextContainer">
        <h2>{t('privacy-subTitle7')}</h2>
        <p>{t('privacy-subText7-1')}</p>
        </div>

        <div className="privacyTextContainer">
        <h2>{t('privacy-subTitle8')}</h2>
        <p>{t('privacy-subText8-1')}</p>
        </div>

        <div className="privacyTextContainer" id="privacyFirstTextContainerLast">
        <h2>{t('privacy-subTitle9')}</h2>
        <p>{t('privacy-subText9-1')}</p>
        </div>

        </div>
      </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Privacy;

// src/components/Calculator.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/calculator.css';

// Define initial state arrays for each category
const livingRoomItems = [
    { name: "SOFA", quantity: 0 },
    { name: "ARMCHAIR", quantity: 0 },
    { name: "COFFEE TABLE", quantity: 0 },
    { name: "TV", quantity: 0 },
    { name: "BOOKSHELF", quantity: 0 },
    { name: "CABINET", quantity: 0 },
    { name: "RUG", quantity: 0 },
    { name: "ENTERTAINMENT CENTER", quantity: 0 },
    { name: "SIDE TABLE", quantity: 0 },
  ];
  
  const bedroomItems = [
    { name: "BED", quantity: 0 },
    { name: "MATTRESS", quantity: 0 },
    { name: "WARDROBE", quantity: 0 },
    { name: "DRESSER", quantity: 0 },
    { name: "NIGHTSTAND", quantity: 0 },
    { name: "DESK", quantity: 0 },
    { name: "CHAIR", quantity: 0 },
    { name: "CHEST OF DRAWERS", quantity: 0 },
    { name: "MIRROR", quantity: 0 },
    { name: "BEDSIDE LAMP", quantity: 0 },
  ];

  const diningRoomItems = [
    { name: "DINING TABLE", quantity: 0 },
    { name: "DINING CHAIRS", quantity: 0 },
    { name: "SIDEBOARD", quantity: 0 },
    { name: "BUFFET TABLE", quantity: 0 },
    { name: "CHINA CABINET", quantity: 0 },
  ];

  const kitchenItems = [
    { name: "REFRIGERATOR", quantity: 0 },
    { name: "OVEN/STOVE", quantity: 0 },
    { name: "DISHWASHER", quantity: 0 },
    { name: "MICROWAVE", quantity: 0 },
    { name: "KITCHEN TABLE", quantity: 0 },
    { name: "KITCHEN CHAIRS", quantity: 0 },
    { name: "KITCHEN ISLAND", quantity: 0 },
    { name: "BAR STOOLS", quantity: 0 },
    { name: "CABINETS", quantity: 0 },
    { name: "PANTRY SHELVES", quantity: 0 },
  ];
  const officeItems = [
    { name: "DESK", quantity: 0 },
    { name: "OFFICE CHAIR", quantity: 0 },
    { name: "FILING CABINET", quantity: 0 },
    { name: "BOOKSHELVES", quantity: 0 },
    { name: "PRINTER", quantity: 0 },
    { name: "COMPUTER MONITOR", quantity: 0 },
    { name: "L-SHAPED DESK", quantity: 0 },
    { name: "CONFERENCE TABLE", quantity: 0 },
    { name: "WHITEBOARD", quantity: 0 },
  ]
  const storageRoomItems = [
    { name: "STORAGE BOXES", quantity: 0 },
    { name: "WARDROBE BOXES", quantity: 0 },
    { name: "TOOL CHEST", quantity: 0 },
    { name: "SHELVING UNIT", quantity: 0 },
  ]
  const applicanceItems = [
    { name: "WASHING MACHINE", quantity: 0 },
    { name: "DRYER", quantity: 0 },
    { name: "VACUUM CLEANER", quantity: 0 },
    { name: "AIR CONDITIONER", quantity: 0 },
    { name: "HEATER", quantity: 0 },
    { name: "DEHUMIDIFIER", quantity: 0 },
  ]
  const miscellaneousItems = [
    { name: "BICYCLES", quantity: 0 },
    { name: "EXERCISE EQUIPMENT", quantity: 0 },
    { name: "MUSICAL INSTRUMENTS", quantity: 0 },
    { name: "LAWN MOWER", quantity: 0 },
    { name: "BBQ GRILL", quantity: 0 },
    { name: "PATIO FURNITURE", quantity: 0 },
  ]

// Define other categories similarly...

function Calculator({ onSelectedItemsChange }) {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('LIVING_ROOM');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [kitchenQuantities, setKitchenQuantities] = useState(kitchenItems);
  const [diningRoomQuantities, setDiningRoomQuantities] = useState(diningRoomItems);
  const [bedroomQuantities, setBedroomQuantities] = useState(bedroomItems);
  const [livingRoomQuantities, setLivingRoomQuantities] = useState(livingRoomItems);
  const [officeQuantities, setOfficeQuantities] = useState(officeItems);
  const [storageRoomQuantities, setStorageRoomQuantities] = useState(storageRoomItems);
  const [appliancesQuantities, setAppliancesQuantities] = useState(applicanceItems);
  const [miscellaneousQuantities, setMiscellaneousQuantities] = useState(miscellaneousItems);

  const openInventoryModal = () => {
    setIsModalOpen(true);
  };

  const closeInventoryModal = () => {
    setIsModalOpen(false);
    const allSelectedItems = [
      ...livingRoomQuantities,
      ...bedroomQuantities,
      ...diningRoomQuantities,
      ...kitchenQuantities,
      ...officeQuantities,
      ...storageRoomQuantities,
      ...appliancesQuantities,
      ...miscellaneousQuantities,
    ].filter(item => item.quantity > 0);
    setSelectedItems(allSelectedItems);
    onSelectedItemsChange(allSelectedItems);
  };

  const switchTab = (tab) => {
    setSelectedTab(tab);
  };

  const handleQuantityChange = (index, isIncrement, tab) => {
    const getQuantities = {
      LIVING_ROOM: [livingRoomQuantities, setLivingRoomQuantities],
      BEDROOM: [bedroomQuantities, setBedroomQuantities],
      DINING_ROOM: [diningRoomQuantities, setDiningRoomQuantities],
      KITCHEN: [kitchenQuantities, setKitchenQuantities],
      OFFICE: [officeQuantities, setOfficeQuantities],
      STORAGE_ROOM: [storageRoomQuantities, setStorageRoomQuantities],
      APPLIANCES: [appliancesQuantities, setAppliancesQuantities],
      MISCELLANEOUS: [miscellaneousQuantities, setMiscellaneousQuantities],
    };

    const [currentQuantities, setQuantities] = getQuantities[tab];
    const quantities = [...currentQuantities];
    const currentQuantity = quantities[index].quantity;

    if (isIncrement) {
      quantities[index].quantity = currentQuantity + 1;
    } else if (currentQuantity > 0) {
      quantities[index].quantity = currentQuantity - 1;
    }

    setQuantities(quantities);
  };

  const handleManualInputChange = (index, event, tab) => {
    const getQuantities = {
      LIVING_ROOM: [livingRoomQuantities, setLivingRoomQuantities],
      BEDROOM: [bedroomQuantities, setBedroomQuantities],
      DINING_ROOM: [diningRoomQuantities, setDiningRoomQuantities],
      KITCHEN: [kitchenQuantities, setKitchenQuantities],
      OFFICE: [officeQuantities, setOfficeQuantities],
      STORAGE_ROOM: [storageRoomQuantities, setStorageRoomQuantities],
      APPLIANCES: [appliancesQuantities, setAppliancesQuantities],
      MISCELLANEOUS: [miscellaneousQuantities, setMiscellaneousQuantities],
    };

    const [currentQuantities, setQuantities] = getQuantities[tab];
    const quantities = [...currentQuantities];
    const newQuantity = event.target.value === '' ? '' : parseInt(event.target.value, 10);
    quantities[index].quantity = newQuantity >= 0 ? newQuantity : 0;

    setQuantities(quantities);
  };

  const handleInputBlur = (index, tab) => {
    const getQuantities = {
      LIVING_ROOM: [livingRoomQuantities, setLivingRoomQuantities],
      BEDROOM: [bedroomQuantities, setBedroomQuantities],
      DINING_ROOM: [diningRoomQuantities, setDiningRoomQuantities],
      KITCHEN: [kitchenQuantities, setKitchenQuantities],
      OFFICE: [officeQuantities, setOfficeQuantities],
      STORAGE_ROOM: [storageRoomQuantities, setStorageRoomQuantities],
      APPLIANCES: [appliancesQuantities, setAppliancesQuantities],
      MISCELLANEOUS: [miscellaneousQuantities, setMiscellaneousQuantities],
    };

    const [currentQuantities, setQuantities] = getQuantities[tab];
    const quantities = [...currentQuantities];

    // Reset to 0 if the value is empty or invalid
    if (quantities[index].quantity === '' || isNaN(quantities[index].quantity)) {
      quantities[index].quantity = 0;
    }

    setQuantities(quantities);
  };

  const renderTableContent = () => {
    const getQuantities = {
      LIVING_ROOM: livingRoomQuantities,
      BEDROOM: bedroomQuantities,
      DINING_ROOM: diningRoomQuantities,
      KITCHEN: kitchenQuantities,
      OFFICE: officeQuantities,
      STORAGE_ROOM: storageRoomQuantities,
      APPLIANCES: appliancesQuantities,
      MISCELLANEOUS: miscellaneousQuantities,
    };
  
    const items = getQuantities[selectedTab];
  
    return (
      <table className="furniture-table">
        <thead>
          <tr>
            <th>{t('Item')}</th>
            <th className="selectionModalHeader">{t('Selection')}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{t(item.name)}</td>
              <td>
                <div className="quantity-control">
                  <button
                    type="button"
                    onClick={() => handleQuantityChange(index, false, selectedTab)}
                    disabled={item.quantity === 0}
                    className="quantity-btn minus"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleManualInputChange(index, e, selectedTab)}
                    onBlur={() => handleInputBlur(index, selectedTab)}
                    className="quantity-input"
                    min="0"
                  />
                  <button
                    type="button"
                    onClick={() => handleQuantityChange(index, true, selectedTab)}
                    className="quantity-btn plus"
                  >
                    +
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="calculator-container">
      <button type="button" className="inventory-button" onClick={openInventoryModal}>
        {t('OpenInventory')}
      </button>

      {selectedItems.length > 0 && (
        <div className="selected-items">
          <h3>{t('selectedItems')}:</h3>
          <ul>
            {selectedItems.map((item, index) => (
              <li key={index}>
                {t(item.name)} - {item.quantity}
              </li>
            ))}
          </ul>
        </div>
      )}

      {isModalOpen && (
        <div className="modal-overlay" onClick={(e) => e.target.classList.contains("modal-overlay") && closeInventoryModal()}>
          <div className="modal">
            <div className="modal-header">
              <h2>{t('Inventory Details')}</h2>
              <button className="close-button" onClick={closeInventoryModal}>
                &times;
              </button>
            </div>
            <div className="tabs">
              <button type="button" className={selectedTab === "LIVING_ROOM" ? "active-tab" : ""} onClick={() => switchTab("LIVING_ROOM")}>{t('LivingRoom')}</button>
              <button type="button" className={selectedTab === "BEDROOM" ? "active-tab" : ""} onClick={() => switchTab("BEDROOM")}>{t('Bedroom')}</button>
              <button type="button" className={selectedTab === "DINING_ROOM" ? "active-tab" : ""} onClick={() => switchTab("DINING_ROOM")}>{t('DiningRoom')}</button>
              <button type="button" className={selectedTab === "KITCHEN" ? "active-tab" : ""} onClick={() => switchTab("KITCHEN")}>{t('Kitchen')}</button>
              <button type="button" className={selectedTab === "OFFICE" ? "active-tab" : ""} onClick={() => switchTab("OFFICE")}>{t('Office')}</button>
              <button type="button" className={selectedTab === "STORAGE_ROOM" ? "active-tab" : ""} onClick={() => switchTab("STORAGE_ROOM")}>{t('StorageRoom')}</button>
              <button type="button" className={selectedTab === "APPLIANCES" ? "active-tab" : ""} onClick={() => switchTab("APPLIANCES")}>{t('Appliances')}</button>
              <button type="button" className={selectedTab === "MISCELLANEOUS" ? "active-tab" : ""} onClick={() => switchTab("MISCELLANEOUS")}>{t('Miscellaneous')}</button>
            </div>
            {renderTableContent()}
            <footer className="summary">
              <button type="button" onClick={closeInventoryModal}>{t('ConfirmSelection')}</button>
            </footer>
          </div>
        </div>
      )}
    </div>
  );
}

export default Calculator;

import '../styles/privacy-responsibility.css';
import WarehousingNavbar from '../components/navbars/WarehousingNavbar';
import * as React from 'react';
import { useTranslation} from 'react-i18next';
import Footer from './Footer'
import { 
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link
} from "react-router-dom";

function Responsibility() {

    const { t, i18n } = useTranslation();
    //i18n.changeLanguage("en");

  return (
    <div className="SloveniaContainer">
    <WarehousingNavbar />
    <div className='heroPage'>
        <div className="contentContainer" id="warehousingContentBg">
          <div className="ResponsibilityContentInnerContainer">
        <div className="privacyFirstTextContainer">
        <h1>{t('responsibility-mainTitle')}</h1>
        <p>{t('responsibility-subText1-1')}</p>
        <p>{t('responsibility-subText1-2')}</p>
        <h2>{t('responsibility-subTitle-1')}</h2>
        <p>{t('responsibility-subTitle0-1')}</p>
        <p>{t('responsibility-subTitle0-2')}</p>
        <p>{t('responsibility-subTitle0-3')}</p>
        <p>{t('responsibility-subTitle0-4')}</p>
        <p>{t('responsibility-subTitle0-5')}</p>
        <p>{t('responsibility-subTitle0-6')}</p>
        <h2>{t('responsibility-subTitle-5')}</h2>
        <ul>
          <li>
          {t('responsibility-subText4-1')}
          </li>
          <li>
          {t('responsibility-subText4-2')}
          </li>
          <li>
          {t('responsibility-subText4-3')}
          </li>
          <li>
            <ul>
            <li>
          {t('responsibility-subText4-4')}
          </li>
          <li>
          {t('responsibility-subText4-5')}
          </li>
          <li>
          {t('responsibility-subText4-6')}
          </li>
          <li>
          {t('responsibility-subText4-7')}
          </li>
          <li>
          {t('responsibility-subText4-8')}
          </li>
          <li>
          {t('responsibility-subText4-9')}
          </li>
            </ul>
          </li>
        </ul>
        <h2>{t('responsibility-subTitle-2')}</h2>
        <ul>
        <li>
        {t('responsibility-subText2-1')}<br />
        </li>
        <li>
        {t('responsibility-subText2-2')}<br />
        </li>
        <li>
        {t('responsibility-subText2-3')}<br />
        </li>
        <li>
        {t('responsibility-subText2-4')}<br />
        </li>
        <li>
        {t('responsibility-subText2-5')}<br />
        </li>
        <li>
        {t('responsibility-subText2-6')}<br />
        </li>
        <li>
        {t('responsibility-subText2-7')}<br />
        </li>
        <li>
        {t('responsibility-subText2-8')}<br />
        </li>
        <li>
        {t('responsibility-subText2-9')}<br />
        </li>
        </ul>
        <h2>{t('responsibility-subTitle-3')}</h2>
        <p>{t('responsibility-subSubTitle-1')}</p>
        <ul>
        <li>
        {t('responsibility-subText3-1')}<br />
        </li>
        <li>
        {t('responsibility-subText3-2')}<br />
        </li>
        <li>
        <ul>
          <li>
          {t('responsibility-subText3-3')}<br />
          </li>
          <li>
          {t('responsibility-subText3-4')}<br />
          </li>
          <li>
          {t('responsibility-subText3-52')}<br />
          </li>
        </ul>
        </li>
        <li>
        {t('responsibility-subText3-6')}<br />
        </li>
        </ul>
        <p>{t('responsibility-subSubTitle-2')}</p>
        <ul>
        <li>
        {t('responsibility-subText3-7')}<br />
        </li>
        <li>
        {t('responsibility-subText3-8')}<br />
        </li>
        <li>
        {t('responsibility-subText3-9')}<br />
        </li>
        <li>
        {t('responsibility-subText3-10')}<br />
        </li>
        </ul>
        <h2>{t('responsibility-subTitle-4')}</h2>
        <p>{t('responsibility-ecoSubtitle')}</p>
        <ul>
        <li>
        {t('responsibility-ecoSubText')}<br />
        </li>
        <li>
        {t('responsibility-ecoSubText1')}<br />
        </li>
        <li>
        {t('responsibility-ecoSubText2')}<br />
        </li>
        <li>
        {t('responsibility-ecoSubText3')}<br />
        </li>
        <li>
        {t('responsibility-ecoSubText4')}<br />
        </li>
        <li>
        {t('responsibility-ecoSubText5')}<br />
        </li>
        <li>
        {t('responsibility-ecoSubText6')}<br />
        </li>
        <li>
        {t('responsibility-ecoSubText7')}<br />
        </li>
        </ul>
        <div className="centerButtonParent">
        <Link to="/slovenia">
        <div className="responsibilityButton">{t('responsibility-sendInquiry')}</div>
        </Link>
        </div>
          

        </div>
      </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Responsibility;
